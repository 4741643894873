const tmsRouteMap = {
  welcome: "/welcome",
  home: "/home",
  login: "/login",
  devotees: "/devotees",
  temple_management: "/temple-management",
  temple_settings: "/temple-settings",
  partners: "/partners",

  update_page: "/update",
  pos: "/pos",
  pos_sevas: "/pos/sevas",
  profile: "/profile",
  // pos_parokshaseva: "/pos/paroksha-seva",
  // pos_pratyakshaseva: "/pos/pratyaksha-seva",
  pos_donation: "/pos/donation",
  pos_petty_donation: "/pos/petty-donation",
  pos_misc: "/pos/miscellaneous",
  pos_darshanam: "/pos/darshanam",
  pos_kalayanakatta: "/pos/kalyanakatta",
  pos_tollgate: "/pos/toll-gate",
  pos_thulabharam: "/pos/thulabharam",
  pos_products: "/pos/products",
  pos_prasadam: "/pos/prasadam",
  pos_accommodation: "/pos/accommodation",
  pos_accommodation_dashboard: "/pos/accommodation-dashboard",

  pos_accommodation_rooms: "/pos/accommodation/rooms",
  pos_prasadm_scanner: "/pos/prasadam-scanner",
  pos_performance_sccanner: "/pos/performance-scanner",
  pos_ticket_details: "/pos/ticket-details",
  pos_check_in_scan: "/pos/check-in-scan",
  pos_pre_assigned_check_in_scan: "/pos/pre-assigned-check-in-scan",
  pos_rents_and_leases: "/pos/rents-and-leases",
  pos_buildings_and_shops: "/pos/rents-and-leases",

  pos_acc_scan: "/pos/accommodation-scan",

  serviceManagement: "/service-management",
  serviceManagement_manual_entry: "/service-management/manual-entry",
  serviceManagement_manual_entry_category:
    "/service-management/manual-entry/Category",
  pos_manual_entry: "/pos/manual-entry",
  report_manual_entry: "/reports/manual-report",

  serviceManagement_counter_management:
    "/service-management/counter-management",
  serviceManagement_counter_management_manage_shifts:
    "/service-management/counter-management/manage-shift",
  serviceManagement_counter_management_manage_devices:
    "/service-management/counter-management/manage-pos-devices",

  serviceManagement_shift_management: "/service-management/shift-management",
  serviceManagement_coupon_management: "/service-management/coupon-management",
  serviceManagement_user_coupon_management:
    "/service-management/coupon-management/registered-user",
  serviceManagement_parokshaseva: "/service-management/paroksha-seva",
  serviceManagement_sevas: "/service-management/sevas",
  sms_add_new_seva: "/service-management/sevas/add-new-seva",
  sms_seva_disable_dates: "/service-management/sevas/disable-dates",
  sms_seva_booking_details: "/service-management/sevas/booking-details",
  sms_seva_quota_management: "/service-management/sevas/quota-management",

  sms_sevas_manage_slots: "/service-management/sevas/manage-slots",
  sms_sevas_manage_partners: "/service-management/sevas/manage-partners",

  sms_sevas_manage_counters:
    "/service-management/sevas/manage-slots/manage-counters",
  sms_sevas_manage_quota: "/service-management/sevas/slots/manage-online-quota",

  serviceManagement_donation: "/service-management/donation",
  sms_add_new_donation: "/service-management/donation/add-new-donation",
  sms_donation_donor_registartion:
    "/service-management/donation/donor-registartion",
  sms_donation_manage_counters: "/service-management/donation/manage-counters",
  sms_donation_manage_partners: "/service-management/donation/manage-partners",
  serviceManagement_addOns: "/service-management/add-ons",

  serviceManagement_darshanam: "/service-management/darshanam",
  //-----------------------Kalayanakatta ---------------------------------//
  serviceManagement_kalayanakatta: "/service-management/kalyanakatta",
  sm_kalyanakatta_add_new_kalyanakatta:
    "/service-management/kalyanakatta/add-new-kalyanakatta",
  sm_kalyanakatta_manage_tonsure:
    "/service-management/kalyanakatta/manage-tonsure",
  sm_kalyanakatta_manage_performer:
    "/service-management/kalyanakatta/manage-performer",
  //-----------------------Kalayanakatta ---------------------------------//
  //-----------------------Entry Scan------------------------//

  serviceManagement_entryScan: "/service-management/entry-scan",
  sm_entry_scan_add_new: "/service-management/entry-scan/add-new-entry-scan",
  sm_entry_scan_manage_counter:
    "/service-management/entry-scan/manage-counters",
  pos_entryScan: "/pos/entry-scan",
  reports_entryScan: "/reports/entry-scan",

  //---------------------------------------------------------//

  serviceManagement_tollgate: "/service-management/toll-gate",
  serviceManagement_misc: "/service-management/miscellaneous",

  serviceManagement_thulabharam: "/service-management/thulabharam",
  serviceManagement_publications: "/service-management/publication",
  pos_publication: "/pos/publication",

  serviceManagement_prasadam: "/service-management/prasadam",

  serviceManagement_footfall: "/service-management/footfall",
  //----------------------------accommodation----------------------------------//
  serviceManagement_accommodation: "/service-management/accommodation",
  sm_accommodation_and: "/service-management/accommodation/add-new",
  sm_accommodation_disable: "/service-management/accommodation/disable",
  sm_acc_manage_pos: "/service-management/accommodation/Manage-pos",
  sms_acc_quota_management:
    "/service-management/accommodation/quota-management",
  sm_acc_manage_room_categories:
    "/service-management/accommodation/manage-room-categories",

  sm_acc_mrc_manage_room:
    "/service-management/accommodation/manage-room-categories/manage-room",
  sm_acc_mrc_manage_quota:
    "/service-management/accommodation/manage-room-categories/manage-quota",
  //----------------------------accommodation----------------------------------//
  //----------------------------rents and leases-------------------------------//
  serviceManagement_rents_and_leases: "/service-management/rents-and-leases",

  sm_ral_lands: "/service-management/rents-and-leases/lands",
  sm_ral_buildings: "/service-management/rents-and-leases/buildings",

  //Lands

  sm_ral_lands_land_creation:
    "/service-management/rents-and-leases/lands/land-creation",
  sm_ral_lands_land_lease_creation:
    "/service-management/rents-and-leases/lands/land-lease-creation",
  sm_ral_lands_arears_creation:
    "/service-management/rents-and-leases/lands/arears-creation",
  sm_ral_lands_enchroament_creation:
    "/service-management/rents-and-leases/lands/encroachment",
  sm_ral_lands_survey_no_creation:
    "/service-management/rents-and-leases/lands/survey-no-creation",
  sm_ral_lands_survey_breakup_no_creation:
    "/service-management/rents-and-leases/lands/survey-no-breakup",

  //----------------------------buildings and shops-------------------------------//

  sm_ral_buildings_creation:
    "/service-management/rents-and-leases/buildings/creation",
  sm_ral_buildings_created_building_creation:
    "/service-management/rents-and-leases/buildings/creation/building-creation",
  sm_ral_buildings_buildings_lease_creation:
    "/service-management/rents-and-leases/buildings/creation/lease-creation",
  sm_ral_buildings_arears_creation:
    "/service-management/rents-and-leases/buildings/creation/arears-creation",
  sm_ral_buildings_enchroament_creation:
    "/service-management/rents-and-leases/buildings/creation/encroachment",
  sm_ral_buildings_blocks_creation:
    "/service-management/rents-and-leases/buildings/creation/block-creation",
  sm_ral_buildings_shops_creation:
    "/service-management/rents-and-leases/buildings/creation/shop-creation",
  //---------------------------------------------------------------------------//

  //----------------------------licenses---------------------------------------//
  sm_ral_licenses: "/service-management/rents-and-leases/license",

  sm_ral_license_creation:
    "/service-management/rents-and-leases/license/creation",
  sm_ral_license_license_creation:
    "/service-management/rents-and-leases/license/creation/license-creation",
  sm_ral_license_lease_creation:
    "/service-management/rents-and-leases/license/creation/lease-creation",
  sm_ral_license_arears_creation:
    "/service-management/rents-and-leases/license/creation/arears-creation",

  //---------------------------------------------------------------------------//

  //---------------------------------fdr--------------------------------------//
  sm_ral_fdr: "/service-management/rents-and-leases/fdr",

  sm_ral_fdr_creation: "/service-management/rents-and-leases/fdr/creation",

  sm_ral_fdr_account_creation:
    "/service-management/rents-and-leases/fdr/account-creation",
  sm_ral_fdr_bank_account_creation:
    "/service-management/rents-and-leases/fdr/bank-account-creation",
  sm_ral_fdr_investment_creation:
    "/service-management/rents-and-leases/fdr/investment-creation",
  sm_ral_fdr_fdr_creation:
    "/service-management/rents-and-leases/fdr/fdr-creation",

  //---------------------------------------------------------------------------//

  serviceManagement_products: "/service-management/products",
  sm_products_and: "/service-management/products/add-new",
  sm_products_manage_pos: "/service-management/products/Manage-pos",

  sm_products_manage_files: "/service-management/products/file-management",
  sm_products_manage_files_add:
    "/service-management/products/file-management/add-new",
  report_products: "/reports/products",
  report_publication: "/reports/publication",

  //----------------------------products---------------------------------------//
  //----------------------------publications-----------------------------------//
  sm_publication_and: "/service-management/publication/add-new",
  sm_publication_manage_pos: "/service-management/publication/Manage-pos",

  sm_publication_manage_files:
    "/service-management/publication/file-management",
  sm_publication_manage_files_add:
    "/service-management/products/file-management/add-new",
  //----------------------------publications-----------------------------------//

  //----------------------------darshanam--------------------------------------//
  smd_darshanam_add_new_darshanam:
    "/service-management/darshanam/add-new-darshanam",
  smd_darshanam_booking_details:
    "/service-management/darshanam/booking-details",
  sms_darshanam_quota_management:
    "/service-management/darshanam/quota-management",
  smd_darshanam_manage_slots: "/service-management/darshanam/manage-slots",
  smd_darshanam_manage_counters:
    "/service-management/darshanam/manage-slots/manage-counters",
  smd_darshanam_manage_quota:
    "/service-management/darshanam/slots/manage-online-quota",
  smd_darshanam_disable_dates: "/service-management/darshanam/disable-dates",
  sms_darshanam_manage_partners:
    "/service-management/darshanam/manage-partners",

  //----------------------------darshanam------------------------------------//
  //----------------------------protocol------------------------------------//
  pos_protocol: "/pos/protocol",
  pos_protocol_dashboard: "/pos/protocol-dashboard",
  pos_protocol_print: "/pos/protocol-print",
  reports_protocol: "/reports/protocol",
  //----------------------------protocol------------------------------------//
  //----------------------------Add ons--------------------------------------//
  sm_addons_add_new_addon: "/service-management/add-ons/add-new-add-ons",
  sm_addons_booking_details: "/service-management/add-ons/booking-details",
  sm_addons_manage_slots: "/service-management/add-ons/manage-slots",
  sm_addons_manage_counters:
    "/service-management/add-ons/manage-slots/manage-counters",
  sm_addons_manage_quota:
    "/service-management/add-ons/slots/manage-online-quota",

  //----------------------------Add ons--------------------------------------//
  smd_prasadam_add_new_prasadam:
    "/service-management/prasadam/add-new-prasadam",
  smd_prasadam_booking_details: "/service-management/prasadam/booking-details",
  smd_prasadam_manage_counter:
    "/service-management/prasadam/manage-slots/manage-counters",

  sm_prasadam_inventory: "/service-management/prasadam-inventory",
  sm_prasadam_inventory_add_new:
    "/service-management/prasadam-inventory/add-new",
  sm_prasadam_inventory_stock: "/service-management/prasadam-inventory/stock",

  sm_thulabharam_add_new_thulabharam:
    "/service-management/thulabharam/add-new-thulabharam",
  sm_thulabharam_booking_details:
    "/service-management/thulabharam/booking-details",
  sm_thulabharam_manage_counter:
    "/service-management/thulabharam/manage-slots/manage-counters",

  sm_tollgate_add_new_tollgate:
    "/service-management/toll-gate/add-new-toll-gate",
  sm_tollgate_booking_details: "/service-management/toll-gate/booking-details",
  sm_tollgate_manage_counter: "/service-management/toll-gate/manage-counters",

  sm_misc_add_new_misc:
    "/service-management/miscellaneous/add-new-miscellaneous",
  sm_misc_booking_details: "/service-management/miscellaneous/booking-details",
  sm_misc_manage_counter: "/service-management/miscellaneous/manage-counters",
  sm_parakamani: "/service-management/parakamani",

  orders: "/orders",
  reports: "/reports",
  reports_parakamani: "/reports/parakamani",
  reports_shift_report: "/reports/shift-report",
  reports_scan_logs: "/reports/scan-logs",
  reports_consolidated_reports: "/reports/consolidated-report",
  reports_all_transactions_reports: "/reports/all-transactions",
  reports_requests: "/reports/requests",

  reports_requests_new: "/reports/requests/new",

  reports_darshanam: "/reports/darshanam",
  reports_darshanam_summary_report: "/reports/darshanam/summary-report",

  reports_prasadam: "/reports/prasadam-counter",
  reports_prasadam_summary_report: "/reports/prasadam/summary-report",

  reports_thulabharam: "/reports/thulabharam-counter",
  reports_thulabharam_summary_report: "/reports/thulabharam/summary-report",

  reports_seva: "/reports/seva",
  reports_seva_summary_report: "/reports/seva/summary-report",

  reports_donation: "/reports/donation",
  reports_donation_summary_report: "/reports/donation/summary-report",

  reports_tollgate: "/reports/tollgate",
  reports_tollgate_summary_report: "/reports/tollgate/summary-report",

  reports_misc: "/reports/miscellaneous-report",
  reports_misc_summary_report: "/reports/miscellaneous/summary-report",

  reports_accommodation: "/reports/accommodation",
  reports_accommodation_summary_report: "/reports/accommodation/summary-report",
  reports_accommodation_renewal_report: "/reports/accommodation/renewal-report",

  reports_kalyanakatta: "/reports/kalyanakatta",
  reports_kalyanakatta_summary_report: "/reports/kalyanakatta/summary-report",
  reports_kalyanakatta_performer_report:
    "/reports/kalyanakatta/performer-report",

  reports_view_shift_transactions: "/reports/shift-report/shift-management",
  reports_view_shift_scan_logs: "/reports/shift-report/scan-logs",
  reports_print_logs: "/reports/print-logs",

  reports_rents_and_leases: "/reports/rents-and-leases",
  reports_ral_lands: "/reports/rents-and-leases/lands",
  reports_ral_buildings: "/reports/rents-and-leases/buildings",
  reports_ral_licenses: "/reports/rents-and-leases/licenses",
  reports_ral_fdr: "/reports/rents-and-leases/fdr",

  reports_ral_lands_encroachment:
    "/reports/rents-and-leases/lands/encroachment",
  reports_ral_lands_leases: "/reports/rents-and-leases/lands/leases",
  reports_ral_lands_arrears: "/reports/rents-and-leases/lands/arrears",
  reports_ral_lands_lands: "/reports/rents-and-leases/lands/lands",

  reports_ral_buildings_building:
    "/reports/rents-and-leases/buildings/building",
  reports_ral_buildings_encroachment:
    "/reports/rents-and-leases/buildings/encroachment",
  reports_ral_buildings_leases: "/reports/rents-and-leases/buildings/leases",
  reports_ral_buildings_arrears: "/reports/rents-and-leases/buildings/arrears",

  reports_ral_licenses_created: "/reports/rents-and-leases/licenses/all",
  reports_ral_licenses_leases: "/reports/rents-and-leases/licenses/leases",
  reports_ral_licenses_arrears: "/reports/rents-and-leases/licenses/arrears",

  userManagement: "/user-management/",
  userManagement_manage_users: "/user-management/manage-users",
  userManagement_user_details: "/user-management/user-details",
  support: "/support",
  calender: "/calender",
  //----------------------------Donor Privileges--------------------------------------//
  sm_donor_p_list: "/service-management/donor-privileges",
  sm_donor_p_create: "/service-management/create-donor-privileges",

  //----------------------------Donor Privileges--------------------------------------//
  //----------------------------Bus--------------------------------------//
  sm_bus: "/service-management/bus",
  sm_bus_add: "/service-management/bus/add-new-bus",
  sm_bus_manage_pos: "/service-management/bus/Manage-pos",
  reports_bus: "/reports/bus",
  pos_bus: "/pos/bus",
  //----------------------------Bus--------------------------------------//
};
export default tmsRouteMap;
