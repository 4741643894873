import React, { useEffect, useState } from "react";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
// import TmsCmsCounters from "../../tms-common-manage-slots/tms-cms-counters";
import TmsDonationInnerPagesAll from "./tms-sm-donation-inner-pages/tms-sm-donation-inner-pages-all";
import TmsDonationInnerPagesAnd from "./tms-sm-donation-inner-pages/tms-sm-donation-inner-pages-and";
import TmsCmsSlots from "../../../tms-common-manage-slots/tms-cms-slots";
import TmsTabsLayout from "../../../../components/tms-customs/tms-tabs-layout";
import tmsRouteMap from "../../../../constants/tms-route.map";
import TmsDonationInnerPagesDonorRegistartion from "./tms-sm-donation-inner-pages/tms-sm-donation-inner-pages-dr";
import TmsDoipPartners from "./tms-sm-donation-inner-pages/tms-doip-partners";
const TmsSmDonation = ({ router }) => {
  const [active, setActive] = useState("All");
  useEffect(() => {
    // console.log(tmsRouteMap.sms_add_new_donation);

    if (router?.location?.pathname === tmsRouteMap.serviceManagement_donation) {
      setActive("all");
      return;
    } else if (
      router?.location?.pathname === tmsRouteMap.sms_donation_donor_registartion
    ) {
      setActive("Donor Registration");
      return;
    } else if (
      router?.location?.pathname.includes(tmsRouteMap.sms_add_new_donation)
    ) {
      setActive("Add New Donation");
    } else if (
      router?.location?.pathname.includes(tmsRouteMap.sms_donation_manage_partners)
    ) {
      setActive("all")
    } else if (
      router?.location?.pathname.includes(
        tmsRouteMap.sms_donation_manage_counters
      )
    ) {
      setActive("all");
      return;
    }
  }, [router?.location?.pathname]);
  const components = [
    {
      name: router?.location?.pathname?.includes(
        tmsRouteMap.sms_donation_manage_partners
      ) ? "Donation - Manage Partners" : "All",
      Element: router?.location?.pathname.includes(
        tmsRouteMap.sms_donation_manage_counters
      ) ? (
        <TmsCmsSlots type="donation-counters" />
      ) : (router?.location?.pathname?.includes(
        tmsRouteMap.sms_donation_manage_partners
      )) ? <TmsDoipPartners /> :(
        <TmsDonationInnerPagesAll />
      ),
      key: "all",
      route: tmsRouteMap.serviceManagement_donation,
    },
    {
      name: "Add New Donation",
      Element: <TmsDonationInnerPagesAnd />,
      key: "Add New Donation",
      route: tmsRouteMap.sms_add_new_donation,
    },
    {
      name: "Donor Registration",
      Element: <TmsDonationInnerPagesDonorRegistartion />,
      key: "Donor Registration",
      route: tmsRouteMap.sms_donation_donor_registartion,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TmsTabsLayout
        components={components}
        active={active}
        setActive={setActive}
      />
    </div>
  );
};
export default withRouter(TmsSmDonation);
