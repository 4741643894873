import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import moment from "moment";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Select,
  Switch,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import TmsStringUtils from "../../../utils/tms-string-utils/tms-string-utils";
import TmsPrintTicket from "../../../components/tms-print-ticket/tms-print-ticket";
import numberToWords from "../../../utils/tms-number-to-words/tms-number-to-words";
import TmsPrasadamService from "../../../services/tms-prasadam.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import "../../../assets/css/tms-prasadam/tms-pos-prasadam.scss";
import {
  dragEnter,
  dragStart,
  drop,
  updateData,
} from "../../../utils/tms-card-dragger/tms-card-dragger";

const TmsPosPrasadam = ({ router }) => {
  const [prasadamList, setPrasadamList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [bookingData, setBookingData] = useState([]);
  const [prasadamCount, setPrasadamCount] = useState([]);
  const [amountpaid, setAmountPaid] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [externalPrint, setExternalPrint] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedPrasadamIds, setSelectedPrasadamIds] = useState([]);
  const [array, setArray] = useState([]);
  const [updateFlag, setUpdateFlag] = useState();
  const [fetchedData, setFetchedData] = useState([]);
  const ref = useRef(null);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [payment, setPayment] = useState("CASH");
  const labelStyles = { float: "left", fontSize: "17px" };

  const prasadamData = (data) => {
    let totalPrice = 0;
    let schemaBody = [];
    let firstItem = data?.cartItems?.prasadam[0];
    data?.cartItems?.prasadam?.forEach((item) => {
      totalPrice += item.prasdamBooking.price;

      schemaBody = [
        ...schemaBody,
        [
          "",
          `<tr style="text-transform:capitalize;"><td style="border:1px solid black">${item.productDetails.productName}</td><td class="col-2" style="border:1px solid black">${item.prasdamBooking.noOfItems}</td><td style="border:1px solid black">Rs. ${item.prasdamBooking.price}.00</td></tr>`,
          {
            display: "none",
          },
        ],
      ];
    });
    let schemaHeader = [
      [
        "header",
        firstItem?.counterCartItem?.categoryTransactionSequence
          ?.split("-")
          ?.pop(),
        {},
        firstItem?.counterCartItem?.dailyCategoryTransactionSequence
          ?.split("-")
          ?.pop(),
        firstItem?.counterCartItem?.ticketId,
      ],
      [
        "Date",
        moment(firstItem?.prasdamBooking?.bookingDate).format("DD-MM-YYYY"),
        { margin: "0" },
      ],
      ["Ticket Id", firstItem?.counterCartItem?.ticketId, { margin: "0" }],
      [
        "",
        `<tr style="text-transform:capitalize;font-weight:bold;"><td>Item</td><td>Qty</td><td>Total</td></tr>`,
        {
          display: "none",
        },
      ],
    ];
    let schemaFooter = [
      [
        "Total Amount",
        `Rs. ${totalPrice}.00 `,
        { margin: "0", fontSize: "22px", fontWeight: "800" },
      ],
      [
        "",
        `(${numberToWords(totalPrice)} Rupees)`,
        { margin: "0", fontWeight: "bold" },
      ],
      [
        "footer",
        firstItem?.counterCartItem?.ticketId,
        {},
        {},
        {},
        !firstItem?.productDetails?.productMetadata?.termsAndConditions ||
        firstItem?.productDetails?.productMetadata?.termsAndConditions[0] === ""
          ? null
          : firstItem?.productDetails?.productMetadata?.termsAndConditions
              ?.map((item, i) => {
                return `<p style="margin:0" key=${i}>${item}</p>`;
              })
              .join(" "),
        firstItem?.counterCartItem?.createdAt,
      ],
    ];
    setArray([...schemaHeader, ...schemaBody, ...schemaFooter]);
  };

  useEffect(() => {
    const localStorageSelectedPrasadamIds = localStorage.getItem(
      "selectedCounterPrasadamIds"
    );

    if (localStorageSelectedPrasadamIds) {
      const ids = localStorageSelectedPrasadamIds.split(",").map(Number);
      setSelectedPrasadamIds(ids);
    } else {
      setSelectedPrasadamIds([]);
    }
  }, []);

  const getPrasadam = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      let res = await TmsPrasadamService.fetchPrasadamBookingSlots();
      setFetchedData(res);
      setPrasadamCount(
        res.map((item) => {
          return {
            id: item?.product?.id,
            price: item?.product?.price,
            count: "",
          };
        })
      );
      updateData(res, setPrasadamList, "prasadamList");
      setLoading(false);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getPrasadam();
  }, [updateFlag]);

  const handleSubmit = async () => {
    setButtonLoading(true);
    setLoading(true);
    let a = false;
    prasadamCount?.forEach((e) => {
      if (e.count !== 0 && e.count > 0) {
        a = true;
      }
    });
    if (!a) {
      notification.error({ message: "Please select atleast one prasadam" });
      setLoading(false);
      setButtonLoading(false);
      return;
    }
    try {
      let body = {
        prasadamItems: [],
      };
      prasadamCount.forEach((item) => {
        if (item.count > 0 && item?.count !== 0) {
          body.prasadamItems.push({
            prasadamId: Number(item?.id),
            noOfItems: Number(item?.count),
          });
        }
      });
      let res = await TmsPrasadamService.prasadamBooking(body, payment);
      notification.success({ message: "Success" });
      prasadamData(res);
      clearFields();
      setLoading(false);
      setIsModalVisible(true);
      setExternalPrint(true);
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
      setLoading(false);
      setButtonLoading(false);
    }
    // setButtonLoading(false);
  };

  const getAmount = () => {
    let amount = 0;
    prasadamCount.forEach((e) => {
      if (e?.count === 0) {
        return;
      } else {
        amount += e?.count * e?.price;
      }
    });
    return amount;
  };

  const clearFields = () => {
    setStartDate("");
    setFetchedData([]);
  };

  const toggleSwitch = (id) => {
    let newIds = [];
    if (selectedPrasadamIds?.includes(id)) {
      newIds = selectedPrasadamIds.filter((e) => e !== id);
    } else {
      newIds = [...selectedPrasadamIds, id];
    }
    setSelectedPrasadamIds(newIds);
    localStorage.setItem("selectedCounterPrasadamIds", newIds);
  };

  const menu = (
    <Menu onClick={() => setMenuVisible(true)}>
      {fetchedData.map((item) => {
        return (
          <Menu.Item
            key={item?.id}
            onClick={() => {
              toggleSwitch(item?.id);
            }}
          >
            {item?.product?.name}
            <Switch
              size="small"
              style={{ float: "right" }}
              checked={selectedPrasadamIds?.includes(item?.id)}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const handleVisibleChange = () => {
    if (menuVisible) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "white",
          padding: "30px 50px",
        }}
      >
        <Row justify="space-between" style={{ textAlign: "left" }}>
          <Col style={{ width: "32%", justifyContent: "start" }}>
            <label className="labelStyles">
              Booking Date{" "}
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />{" "}
            <DatePicker
              disabled={true}
              value={startDate}
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
              disabledDate={(d) =>
                !d || d.isBefore(TmsStringUtils.addDays(new Date(), -1))
              }
              onChange={(date) => {
                setStartDate(date);
              }}
              size="medium"
            />
          </Col>
          <Col style={{ width: "32%", justifyContent: "start" }}>
            <label className="labelStyles">Prasadam Menu </label>
            <br />{" "}
            <Dropdown
              overlay={menu}
              placement="bottomLeft"
              onVisibleChange={() => handleVisibleChange()}
              visible={menuVisible}
            >
              <Button
                style={{ width: "100%", textAlign: "left" }}
                size="medium"
              >
                Select Menu
              </Button>
            </Dropdown>
          </Col>
          <Col style={{ width: "32%", justifyContent: "start" }}>
            <label style={labelStyles}>
              Select Payment Type
              <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            </label>
            <br />
            <Select
              size="medium"
              style={{ width: "100%" }}
              placeholder="Select payment type"
              optionFilterProp="children"
              value={payment}
              onChange={(e) => {
                setPayment(e);
              }}
            >
              <Select.Option key="CASH" value="CASH">
                Cash
              </Select.Option>
              <Select.Option key="UPI" value="UPI">
                UPI
              </Select.Option>
              <Select.Option key="CARD" value="CARD">
                Card
              </Select.Option>
              <Select.Option key="CHEQUE" value="CHEQUE">
                Cheque
              </Select.Option>
              <Select.Option key="DD" value="DD">
                DD
              </Select.Option>
            </Select>
          </Col>
        </Row>
        <br />
        <Row
          style={{
            backgroundColor: "#ECF0F5",
            borderRadius: "2px 2px 0px 0px",
            // paddingRight: "18px",
          }}
        >
          <Col
            style={{
              width: "25%",
              padding: "15px 10px",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            Item Name
          </Col>

          <Col
            style={{
              width: "25%",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Unit Price
          </Col>
          <Col
            style={{
              width: "25%",
              display: "flex",
              alignItems: "center",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Quantity
          </Col>
          <Col
            style={{
              width: "25%",
              display: "flex",
              alignItems: "center",
              padding: "15px 10px",
              borderLeft: "1px solid #CDD7E2",
            }}
          >
            Net Amount
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{ overflow: "hidden" }}
          className="posItemsList"
        >
          {prasadamList
            ?.filter((item) => selectedPrasadamIds?.includes(item?.id))
            ?.map((item, i) => {
              return (
                <Card
                  key={i}
                  hoverable
                  style={{ width: "100%" }}
                  className="itemCard"
                  onDragStart={(e) => dragStart(e, i, dragItem)}
                  onDragEnter={(e) => dragEnter(e, i, dragOverItem)}
                  onDragEnd={(e) =>
                    drop(
                      e,
                      setUpdateFlag,
                      dragItem,
                      dragOverItem,
                      "prasadamList",
                      selectedPrasadamIds
                    )
                  }
                  draggable
                >
                  <Row style={{ padding: "0" }}>
                    <Col
                      style={{
                        width: "25%",
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontWeight: "600",
                        padding: "10px",
                      }}
                    >
                      {item?.product.name}{" "}
                      {item?.availableBookingCount
                        ? ` - (Stock: ${item?.availableBookingCount})`
                        : ""}
                      <Divider type="vertical" />
                    </Col>

                    <Col
                      style={{
                        width: "25%",
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontWeight: "600",
                        padding: "10px",
                        borderLeft: "1px solid #CDD7E2",
                      }}
                    >
                      Rs. {item?.product?.price}
                    </Col>
                    <Col
                      style={{
                        width: "25%",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        borderLeft: "1px solid #CDD7E2",
                      }}
                    >
                      <Input
                        id={`input-navigation-${i}`}
                        tabIndex={i}
                        onWheel={(event) => event.currentTarget.blur()}
                        style={{ width: "70px", textAlign: "center" }}
                        type="number"
                        min="0"
                        max={item?.product.maxItemsPerOrder ?? "30"}
                        maxLength="2"
                        key="edit"
                        placeholder="0"
                        autoFocus={i === 0 ? true : false}
                        value={
                          prasadamCount?.find((e) => e.id === item?.product?.id)
                            ?.count
                        }
                        controls={false}
                        step="false"
                        onKeyDown={(E) => {
                          if (E.key === "Enter") {
                            E.preventDefault();
                            buttonLoading === false && handleSubmit();
                          }
                          if (E.key === "ArrowUp") {
                            E.preventDefault();
                            window.setTimeout(function () {
                              document
                                .getElementById(`input-navigation-${i - 1}`)
                                ?.focus();
                            }, 0);
                          }
                          if (E.key === "ArrowDown") {
                            E.preventDefault();

                            window.setTimeout(function () {
                              document
                                .getElementById(`input-navigation-${i + 1}`)
                                ?.focus();
                            }, 0);
                          }
                        }}
                        onChange={(event) => {
                          let limit = item?.product?.maxItemsPerOrder ?? 10;
                          if (event.target.value > limit) {
                            return;
                          } else {
                            let data = [...prasadamCount];
                            data.forEach((e) => {
                              if (e?.id === item?.product?.id) {
                                e.count = event.target.value;
                              }
                            });
                            setPrasadamCount(data);
                          }
                        }}
                      />
                    </Col>
                    <Col
                      style={{
                        width: "25%",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        borderLeft: "1px solid #CDD7E2",
                      }}
                    >
                      <div key={i}>
                        <span style={{ color: "red", fontWeight: "600" }}>
                          Rs.{" "}
                          {prasadamCount?.find(
                            (e) => e.id === item?.product?.id
                          )?.price *
                            prasadamCount?.find(
                              (e) => e.id === item?.product?.id
                            )?.count}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              );
            })}
        </Row>
      </div>
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
          background: "#FFFCDC",
        }}
      >
        <Row
          style={{
            alignItems: "center",
            textAlign: "left",
            padding: "10px 50px",
          }}
        >
          <Col
            className="amountLabelStyles"
            style={{
              width: "260px",
              fontSize: "24px",
              marginRight: "20px",
            }}
          >
            Amount Paid: Rs.
            <Input
              maxLength={220}
              className="amountPaidStyle"
              type="number"
              min=""
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) => {
                setAmountPaid(e.target.value);
              }}
            />
          </Col>
          <Col className="amountLabelStyles">
            Amount to be Returned: Rs.{" "}
            {amountpaid - getAmount() > 0 ? amountpaid - getAmount() : 0}
          </Col>
        </Row>
        <Divider style={{ margin: "0" }} />
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "500",
            padding: "10px 50px",
          }}
        >
          <p
            style={{
              fontSize: "24px",
              textAlign: "left",
              margin: "0",
            }}
          >
            Total Amount :
          </p>
          <p
            style={{
              fontSize: "24px",
              margin: "0",
              textAlign: "right",
            }}
          >
            Rs. {Number(getAmount() ?? 0)}.00
          </p>
        </Row>
      </div>
      <br />
      <br />
      <div
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
      >
        <Row style={{ justifyContent: "end", width: "100%" }}>
          <Col
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              ref={ref}
              type="primary"
              style={{
                width: "100%",
                background: "#E11513",
                borderColor: "#E11513",
                height: "42px",
                borderRadius: "3px",
              }}
              onClick={() => {
                buttonLoading === false && handleSubmit();
              }}
              loading={buttonLoading}
            >
              Submit <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      {/* <Modal
        visible={ticketDetailsVisible}
        okText={"Submit"}
        cancelText={"Return"}
        width="600px"
      ></Modal> */}
      <Modal
        visible={isModalVisible}
        width={400}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <TmsPrintTicket
          type="prasadam"
          from="Counter"
          data={array}
          setData={setArray}
          externalPrint={externalPrint}
          setExternalPrint={setExternalPrint}
          setButtonLoading={setButtonLoading}
          setPrintTicketModal={setIsModalVisible}
        />
      </Modal>
    </div>
  );
};
export default withRouter(TmsPosPrasadam);
