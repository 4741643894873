import { Space } from "antd";
import React from "react";
import TmsDonationService from "../../../services/tms-donation.service";
import catchErrorFunction from "../../../utils/tms-catch-error/tms-catch-error";
import {
  EditOutlined,
  EyeOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import { withRouter } from "../../../utils/tms-with-router/tms-with-router";
import tmsRouteMap from "../../../constants/tms-route.map";

const TmsDonationinnerActions = ({
  record,
  setLoading,
  setViewDonationData,
  settmsModalVisible,
  // eslint-disable-next-line no-unused-vars
  setUpdateFlag,
  setEditFlag,
  setProductId,
  router,
  setShowDrawer,
  setTermsData,
  setOnlineTermsData,
}) => {
  return (
    <Space size="middle">
      <a
        onClick={() => {
          router.navigate(
            `${tmsRouteMap.sms_donation_manage_counters}?id=${record.key}&name=${record?.name}`
          );
        }}
      >
        Manage POS
      </a>
      <a
        onClick={() => {
          router.navigate(
            `${tmsRouteMap.sms_donation_manage_partners}?id=${record.key}&name=${record?.name}`
          );
        }}
      >
        Manage Partners
      </a>
      <a
        onClick={async () => {
          setLoading(true);
          try {
            const data = await TmsDonationService.viewDonation(record?.key);
            // console.log(darData);
            setViewDonationData(data);
            settmsModalVisible(true);
          } catch (error) {
            catchErrorFunction(
              error,
              "Token Expired, redirecting to login page in 5 seconds",
              router
            );
          }
          setLoading(false);
        }}
      >
        <EyeOutlined />
      </a>
      {/* <Popconfirm
        title="Are you sure delete this darshanam?"
        onConfirm={async () => {
          setLoading(true);
          try {
            await TmsDonationService.deleteDonation(record?.key);
            notification.success({
              message: "Darshanam Deleted Successful",
            });
            setUpdateFlag(Math.Random + 1);
          } catch (error) {
            catchErrorFunction(
              error,
              "Token Expired, redirecting to login page in 5 seconds",
              router
            );
          }
          setLoading(false);
        }}
        okText="Yes"
        cancelText="No"
      >
        <a>
          <DeleteOutlined />
        </a>
      </Popconfirm> */}
      <a
        onClick={async () => {
          setLoading(true);
          try {
            const darData = await TmsDonationService.viewDonation(record?.key);
            setViewDonationData(darData);
            settmsModalVisible(true);
            setEditFlag(true);
            setProductId(record?.key);
          } catch (error) {
            catchErrorFunction(
              error,
              "Token Expired, redirecting to login page in 5 seconds",
              router
            );
          }
          setLoading(false);
        }}
      >
        <EditOutlined />
      </a>
      <a
        onClick={() => {
          setShowDrawer(true);
          setProductId(record?.key);
          setTermsData(record?.metadata?.termsAndConditions);
          setOnlineTermsData(record?.metadata?.onlineTermsAndConditions);
        }}
      >
        <FileProtectOutlined />
      </a>
    </Space>
  );
};
export default withRouter(TmsDonationinnerActions);
