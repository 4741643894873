import { Button, Col, Input, notification, Row } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import catchErrorFunction from "../../../../utils/tms-catch-error/tms-catch-error";
import { withRouter } from "../../../../utils/tms-with-router/tms-with-router";
import TmsTable from "../../../../components/tms-customs/tms-table";
import TmsDatePickerRange from "../../../../components/tms-customs/tms-datepicker-range";
import TmsCounterService from "../../../../services/tms-counter.service";
import TmsShiftService from "../../../../services/tms-shift.service";
import TmsSelect from "../../../../components/tms-customs/tms-select";
import TmsThulabharamService from "../../../../services/tms-thulabharam.service";
import TmsExcelButton from "../../../../components/tms-customs/tms-excel-button";
import { exportExcel } from "../../../../utils/tms-excel/tms-excel";

const TmsReportThulabharamReport = ({ router }) => {
  const [loading, setLoading] = useState();
  const [excelLoading, setExcelLoading] = useState(false);

  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingFromDate, setBookingFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [bookingToDate, setBookingToDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [bookingType, setBookingType] = useState("POS");
  const [tableCurrentPage, setTabelCurrentPage] = useState(1);
  const [tablePageSize, setTabelPageSize] = useState(15);
  const [tableTotalSize, setTabelTotalSize] = useState(0);
  const [thulabharamType, setThulabharamType] = useState("");
  const [thulabharamList, setThulabharamList] = useState([]);

  const [counter, setCounter] = useState("");
  const [counterList, setCounterList] = useState([]);

  const [shift, setShift] = useState("");
  const [shiftList, setShiftList] = useState([]);

  const fetchBookingDetails = async () => {
    setLoading(true);
    try {
      const data = await TmsThulabharamService.thulabharamReport({
        format: "json",
        tableCurrentPage: Number(tableCurrentPage),
        tablePageSize: Number(tablePageSize),
        fromDate: bookingFromDate,
        toDate: bookingToDate,
        counter,
        shift,
        thulabharamType,
      });
      setTabelTotalSize(data?.meta?.totalItems);
      setBookingDetails(
        data?.items?.map((item) => {
          return { key: item?.bookingId, ...item };
        })
      );
    } catch (error) {
      catchErrorFunction(
        error,
        "Token Expired, redirecting to login page in 5 seconds",
        router
      );
    }
    setLoading(false);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          maxLength={220}
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, filters, sorter) => {
    setTabelCurrentPage(pagination.current);
    setTabelPageSize(pagination.pageSize);
  };

  const fetchThulabharamList = async () => {
    try {
      let res = await TmsThulabharamService.getThulabharam(1, 200);
      setThulabharamList(res.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchCounterList = async () => {
    try {
      let res = await TmsCounterService.getAllCounter();
      setCounterList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const fetchShiftList = async () => {
    try {
      let res = await TmsShiftService.getAllShifts();
      setShiftList(res?.items);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  };
  const handleExcelExport = async () => {
    setExcelLoading(true);
    try {
      const data = await TmsThulabharamService.thulabharamReport({
        format: "json",
        tableCurrentPage: Number(tableCurrentPage),
        tablePageSize: Number(70000),
        fromDate: bookingFromDate,
        toDate: bookingToDate,
        counter,
        shift,
        thulabharamType,
      });

      const compiledData = data.items.map((item, i) => {
        return {
          "S.no": i + 1,
          "POS Name": item.counterName,
          "User Name": item.userName,
          "User ID": item.userId,
          "Shift ID": item.counterShiftTimingId,
          "Booking Id": item.bookingId,
          "Transaction Id": item?.categoryTransactionSequence
            ?.split("-")
            ?.pop(),
          "Item Name ": item.thulabharamName,
          "Booking Date": item.createdDate
            ? moment(item.createdDate).format("DD-MM-YYYY hh:mm A")
            : "-",
          Price: item.price / item.noOfItems,
          Quantity: item.noOfItems,
          "Total amount": item.price,
          Scan: item.scannedDate
            ? moment(item.scannedDate).format("DD-MM-YYYY")
            : "-",
        };
      });
      exportExcel(
        compiledData,
        `thulabharam-report-${moment().format("DD-MM-YYYY")}`,
        setExcelLoading
      );
    } catch (error) {
      catchErrorFunction(error, "Token expired!! Please login again.", router);
      setExcelLoading(false);
    }
  };

  useEffect(() => {
    fetchThulabharamList();
    fetchCounterList();
    fetchShiftList();
  }, []);
  useEffect(() => {
    fetchBookingDetails();
  }, [tableCurrentPage, tablePageSize, bookingType]);

  const columns = [
    {
      title: "S.no",
      key: "sno",
      width: "20px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "POS Name",
      dataIndex: "counterName",
      key: "counterName",
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Shift Id",
      dataIndex: "counterShiftTimingId",
      key: "counterShiftTimingId",
    },

    {
      title: "Txn Id",
      dataIndex: "categoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "S no",
      dataIndex: "dailyCategoryTransactionSequence",
      render: (text) => text?.split("-")?.pop(),
    },
    {
      title: "Booking Id",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Item name",
      dataIndex: "thulabharamName",
      key: "thulabharamName",
      ...getColumnSearchProps("thulabharamName"),
    },
    {
      title: "Booking Date",
      dataIndex: "createdDate",
      render: (record) => {
        return moment(record).format("DD-MM-YYYY");
      },
      sorter: (a, b) => a.createdDate > b.createdDate,
    },
    {
      title: "Price",
      dataIndex: "productPrice",
      key: "productPrice",
      render: (text, object) => {
        return `Rs. ${object?.price / object?.noOfItems}`;
      },
      sorter: (a, b) => a.price > b.price,
    },
    {
      title: "Quantity",
      dataIndex: "noOfItems",
      key: "noOfItems",
    },
    {
      title: "Total amount",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `Rs. ${text}`;
      },
    },
    {
      title: "Scan",
      dataIndex: "scannedDate",
      render: (record) => {
        return record ? moment(record).format("DD-MM-YYYY") : "-";
      },
    },

    // {
    //   title: "Order Date",
    //   dataIndex: "createdDate",
    //   key: "createdDate",
    //   render: (text) => {
    //     return moment(text).format("DD-MM-YYYY");
    //   },
    //   defaultSortOrder: "descend",
    //   sorter: (a, b) => a.createdDate > b.createdDate,
    // },
  ];

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <TmsDatePickerRange
            className={"m-0"}
            label="Time period"
            to={bookingToDate}
            from={bookingFromDate}
            setFrom={setBookingFromDate}
            setTo={setBookingToDate}
          />
        </Col>
        <TmsSelect
          type="col"
          span={3}
          label="Booking Type"
          setValue={setBookingType}
          value={bookingType}
          data={[
            { id: "pos", name: "pos" },
            // { id: "online", name: "online" },
          ]}
          optional={true}
        />
        <TmsSelect
          type="col"
          span={5}
          selectClass="w-100"
          label="Thulabharam Type"
          setValue={(e) => {
            setThulabharamType(e);
          }}
          value={thulabharamType}
          data={thulabharamList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="POS"
          setValue={setCounter}
          value={counter}
          data={counterList}
        />
        <TmsSelect
          type="col"
          span={3}
          label="Shift"
          setValue={setShift}
          value={shift}
          data={shiftList}
        />

        <Col>
          <Row justify="start" style={{ marginBottom: "5px" }}>
            Update
          </Row>
          <Button type="primary" onClick={fetchBookingDetails}>
            Fetch
          </Button>
        </Col>
        <TmsExcelButton
          loading={excelLoading}
          handleExcelExport={handleExcelExport}
          top={true}
        />
      </Row>
      <br />
      <Row>
        <TmsTable
          columns={columns}
          dataSource={bookingDetails}
          loading={loading}
          handleTableChange={handleTableChange}
          pagination={{
            total: tableTotalSize,
            current: tableCurrentPage,
            pageSize: tablePageSize,
          }}
        ></TmsTable>
      </Row>
    </>
  );
};

export default withRouter(TmsReportThulabharamReport);
