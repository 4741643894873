import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";

const TmsShiftService = {
  //------------Shift Timing API(Shift Management) ---------------//
  getAllShifts: async () => {
    const resp = await Axios.get(`v1/counter-shift-timing/all`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  addShift: async (body) => {
    const resp = await Axios.post(`v1/counter-shift-timing/create`, body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });

    return resp.data;
  },

  updateShift: async (body, id) => {
    const resp = await Axios.post(
      `v1/counter-shift-timing/update/${id}`,
      body,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );

    return resp.data;
  },

  getShiftById: async (id) => {
    const resp = await Axios.get(`v1/counter-shift-timing/byId/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteShiftById: async (id) => {
    const resp = await Axios.delete(`v1/counter-shift-timing/delete/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  //------------Shift Timing API(Shift Management) ---------------//

  //------------Shift Login API (Login) ---------------//

  IsShiftLoggedIn: async () => {
    const resp = await Axios.get(`v1/counter-shift-login/is-logged-in`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  shiftLogIn: async (preShiftId) => {
    const resp = await Axios.post(
      `v1/counter-shift-login/log-in?preAssignedShiftId=${preShiftId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  shiftLogOut: async () => {
    const resp = await Axios.post(
      `v1/counter-shift-login/log-out`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //------------Shift Login API (Login) ---------------//

  //-Shift in button pre-shifts fetch
  GetCounterForUserPreShift: async () => {
    const resp = await Axios.get(
      `v1/counter-pre-assign-shift/user-available-shifts`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  ShiftReportChallan: async (shiftId, header) => {
    let url = header
      ? `v1/counter-cart-item/shift-report-challan/${shiftId}?includeTables=true&includePaymentBifurcation=true`
      : `v1/counter-cart-item/shift-report-challan/${shiftId}?includePaymentBifurcation=true`;
    const resp = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  GetCounterForRole: async () => {
    const resp = await Axios.get(`v1/counter/for-role`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  GetCounterForUserShift: async () => {
    const resp = await Axios.get(`v1/counter/for-user-shift`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getCurrentShiftDetails: async () => {
    const resp = await Axios.get(
      `v1/counter-cart-item/current-shift-challan?includeTables=true`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //----------------------------------- shift reports----------------------------------//

  allShiftReports: async (
    shiftId,
    counterId,
    fromDate,
    toDate,
    page,
    limit
  ) => {
    let query = `v1/counter-shift-report/all-shift-login-log?bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${page}&limit=${limit}&`;

    if (counterId !== 0) {
      query += `counterId=${Number(counterId)}&`;
    }

    if (shiftId !== 0) {
      query += `counterShiftTimingId=${Number(shiftId)}`;
    }

    const resp = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  allShiftTransactions: async (logID, page, limit) => {
    const resp = await ReportsAxios.get(
      `v1/counter-shift-report/shift-transactions/${logID}?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //------------------------------------shift reports----------------------------------//
  // getScanTicketLogs: async (shiftLogID, outcome) => {
  //   let resp = await Axios.get(
  //     `v1/scan-ticket-log/transactions/${shiftLogID}?outcome=${outcome}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "X-Temple-Id": localStorage.getItem("templeId"),
  //       },
  //     }
  //   );
  //   return resp.data;
  // },

  getShiftScanTicketLogs: async (id, page, limit, toDate, fromDate) => {
    let url = `v1/scan-ticket/scan-ticket-logs/?counterShiftLoginLogId=${id}&page=${page}&limit=${limit}`;
    if ((toDate !== "") & (fromDate !== "")) {
      url += `&toDate=${toDate}&fromDate${fromDate}`;
    }
    const resp = await ReportsAxios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getAllScanLogs: async (
    page,
    limit,
    toDate,
    fromDate,
    ticket,
    outcome,
    productType
  ) => {
    let url = `v1/scan-ticket/scan-ticket-logs/?page=${page}&limit=${limit}`;
    if ((toDate !== "") & (fromDate !== "")) {
      url += `&toDate=${toDate}&fromDate=${fromDate}`;
    }
    if (ticket !== "") {
      url += `&ticketId=${ticket}`;
    }
    if (outcome !== "") {
      url += `&outcome=${outcome}`;
    }
    if (productType !== "") {
      url += `&productType=${productType}`;
    }
    const resp = await ReportsAxios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};

export default TmsShiftService;
