import Axios from "../utils/tms-axios/tms-axios";
import ReportsAxios from "../utils/tms-axios/tms-reports-axios";

const TmsDonationService = {
  getDonations: async (page, limit) => {
    const resp = await Axios.get(
      `v1/donation/all?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createDonation: async (body) => {
    const resp = await Axios.post(
      `v1/donation/create`,
      {
        name: body.name,
        description: body.description,
        counterEnabled: body.counterEnabled,
        onlineEnabled: body.onlineEnabled,
        price: body.price,
        imageUrl: body.imageUrl,
        maxAmount: body.maxAmount,
        donationType: body.donationType,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteDonation: async (productId) => {
    const resp = await Axios.delete(`v1/donation/delete/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  viewDonation: async (productId) => {
    const resp = await Axios.get(`v1/donation/by-id/${productId}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editDonation: async (productId, body) => {
    const resp = await Axios.put(
      `v1/donation/update/${productId}`,
      {
        name: body.name,
        description: body.description,
        counterEnabled: body.counterEnabled,
        onlineEnabled: body.onlineEnabled,
        price: body.price,
        imageUrl: body.imageUrl,
        maxAmount: body.maxAmount,
        donationType: body.donationType,
        // targetDate: moment(body.targetDate).format("YYYY-MM-DD"),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  //donor-registartion
  getDonationDonors: async (page, limit) => {
    const resp = await Axios.get(
      `v1/coupon-acc-donor-registry/all?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return resp.data;
  },
  createDonor: async (body) => {
    const resp = await Axios.post(`v1/coupon-acc-donor-registry/new`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editDonor: async (body, id) => {
    const resp = await Axios.put(
      `v1/coupon-acc-donor-registry/update/${id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  //darshanam-counters
  getDonationCounters: async (id) => {
    const resp = await Axios.get(`v1/donation-counter/by-product/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  createDonationCounters: async (body) => {
    const resp = await Axios.post(`v1/donation-counter/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editDonationCounters: async (body, slotId) => {
    const resp = await Axios.put(`v1/donation-counter/update/${slotId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  deleteDonationCounters: async (slotId) => {
    const resp = await Axios.delete(`v1/donation-counter/delete/${slotId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  updateTerms: async (productId, termsData, onlinetermsData) => {
    const resp = await Axios.patch(
      `v1/donation/metadata/${productId}`,
      {
        termsAndConditions: termsData,
        onlineTermsAndConditions: onlinetermsData,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //darshanam booking

  fetchDonationBookingSlots: async (type) => {
    const resp = await Axios.get(
      `v1/donation-counter-booking/valid-counter-products?donationType=${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  donationBooking: async (body, payment, cashReference, cashRemarks) => {
    const resp = await Axios.post(
      `v1/counter-cart/new`,
      {
        donationBookings: [body],
        paymentType: payment,
        cashRemarks: cashReference,
        cashReference: cashRemarks,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  donationBookingDetails: async (
    format,
    fromDate,
    toDate,
    dateType,
    sortType,
    page,
    limit,
    selectedDonationId
  ) => {
    let url = `v1/donation-counter-booking/reports?format=${format}&page=${page}&limit=${limit}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${sortType}`;
    if (selectedDonationId && selectedDonationId !== "") {
      url += `&productId=${Number(selectedDonationId)}`;
    }
    const resp = await ReportsAxios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  donationReport: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    sortType,
    counter,
    shift,
    donationType,
  }) => {
    let url = `v1/donation-counter-report/booking?format=${format}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}&order=${sortType}`;
    console.log(donationType);
    if (donationType && donationType !== "") {
      url += `&productId=${Number(donationType)}`;
    }
    if (counter && counter !== "") {
      url += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      url += `&counterShiftTimingId=${shift}`;
    }
    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  donationOnlineReport: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    sortType,
    selectedDonationId,
    paymentStatus,
  }) => {
    let url = `v1/donation-online-report/booking?format=${format}&paymentStatus=${paymentStatus}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&page=${tableCurrentPage}&limit=${tablePageSize}&order=${sortType}`;
    if (selectedDonationId && selectedDonationId !== "") {
      url += `&productId=${Number(selectedDonationId)}`;
    }

    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  getConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    counter,
    shift,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/donation-counter-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  getOnlineConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/donation-online-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  getPartnersForDonation: async (productId) => {
    const resp = await Axios.get(`v1/donation/partners/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  donationPartnerAccess: async (body) => {
    const resp = await Axios.patch(`v1/donation/partner-access`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};

export default TmsDonationService;
