import tmsRouteMap from "./tms-route.map";
//----------------------constants-------------------------//
const service = {
  name: "Service Management",
  path: tmsRouteMap.serviceManagement,
};
const service_darshanam = {
  name: "Darshanam",
  path: tmsRouteMap.serviceManagement_darshanam,
};
const service_addOn = {
  name: "Add-Ons",
  path: tmsRouteMap.serviceManagement_addOns,
};
const misc = {
  name: "Miscellaneous",
  path: tmsRouteMap.serviceManagement_misc,
};
const publication = {
  name: "Publications",
  path: tmsRouteMap.serviceManagement_publications,
};
const reports = { name: "Reports", path: tmsRouteMap.reports };
const service_sevas = {
  name: "Sevas",
  path: tmsRouteMap.serviceManagement_sevas,
};
const products = {
  name: "Products",
  path: tmsRouteMap.serviceManagement_products,
};
const donation = {
  name: "Donation",
  path: tmsRouteMap.serviceManagement_donation,
};
const prasadam = {
  name: "Prasadam",
  path: tmsRouteMap.serviceManagement_prasadam,
};
const tollgate = {
  name: "Toll Gate",
  path: tmsRouteMap.serviceManagement_tollgate,
};
const thulabharam = {
  name: "Thulabharam",
  path: tmsRouteMap.serviceManagement_thulabharam,
};
const kalyankatta = {
  name: "Kalyanakatta",
  path: tmsRouteMap.serviceManagement_kalayanakatta,
};
const prasadamInventory = {
  name: "Prasadam Stock",
  path: tmsRouteMap.sm_prasadam_inventory,
};
const pos = { name: "POS", path: tmsRouteMap.pos };
const accommodation = {
  name: "Accommodation",
  path: tmsRouteMap.serviceManagement_accommodation,
};
const manual_entry = {
  name: "Manual Entry",
  path: tmsRouteMap.serviceManagement_manual_entry,
};
const entryScan = {
  name: "Entry Scan",
  path: tmsRouteMap.serviceManagement_entryScan,
};

const rents_and_leases = {
  name: "Rents and Leases",
  path: tmsRouteMap.serviceManagement_rents_and_leases,
};
const rents_and_leases_lands = {
  name: "Lands",
  path: tmsRouteMap.sm_ral_lands,
};
const rents_and_leases_lands_reports = {
  name: "Land Reports",
  path: tmsRouteMap.reports_ral_lands,
};
const rents_and_leases_buildings = {
  name: "Buildings",
  path: tmsRouteMap.sm_ral_buildings,
};
const rents_and_leases_building_reports = {
  name: "Building Reports",
  path: tmsRouteMap.reports_ral_buildings,
};
const rents_and_leases_fdr = {
  name: "FDR",
  path: tmsRouteMap.sm_ral_fdr,
};
const rents_and_leases_licenses = {
  name: "Licenses",
  path: tmsRouteMap.sm_ral_licenses,
};
const rents_and_leases_license_reports = {
  name: "License Reports",
  path: tmsRouteMap.reports_ral_licenses,
};
const privilege = {
  name: "Donor Privilege",
  path: tmsRouteMap.sm_donor_p_list,
};
const couponManagement = {
  name: "Coupon management",
  path: tmsRouteMap.serviceManagement_coupon_management,
};
const bus = {
  name: "Bus",
  path: tmsRouteMap.sm_bus,
};
//-------------------------------------------------------//

const tmsBreadcrumbMap = {
  [tmsRouteMap.partners]: [
    {
      name: "Partners",
      path: "",
    },
  ],
  [tmsRouteMap.welcome]: [{ name: "Welcome", path: tmsRouteMap.welcome }],

  [tmsRouteMap.home]: [{ name: "Dashboard", path: tmsRouteMap.home }],
  [tmsRouteMap.devotees]: [{ name: "Devotees", path: tmsRouteMap.devotees }],

  [tmsRouteMap.temple_management]: [
    { name: "Temple Management", path: tmsRouteMap.temple_management },
  ],
  [tmsRouteMap.temple_settings]: [
    { name: "Temple Settings", path: tmsRouteMap.temple_settings },
  ],
  [tmsRouteMap.profile]: [{ name: "Profile", path: tmsRouteMap.profile }],

  [tmsRouteMap.pos]: [{ name: "POS", path: tmsRouteMap.pos }],

  // [tmsRouteMap.pos_parokshaseva]: [
  //   pos,
  //   { name: "Paroksha seva", path: tmsRouteMap.pos_parokshaseva },
  // ],

  [tmsRouteMap.pos_sevas]: [
    pos,
    { name: "Sevas", path: tmsRouteMap.pos_sevas },
  ],
  [tmsRouteMap.pos_darshanam]: [
    pos,
    { name: "Darshanam", path: tmsRouteMap.pos_darshanam },
  ],
  [tmsRouteMap.pos_misc]: [
    pos,
    { name: "Miscellaneous", path: tmsRouteMap.pos_misc },
  ],
  [tmsRouteMap.pos_donation]: [
    pos,
    { name: "Donation", path: tmsRouteMap.pos_donation },
  ],
  [tmsRouteMap.pos_petty_donation]: [
    pos,
    { name: "Petty Donation", path: tmsRouteMap.pos_petty_donation },
  ],
  [tmsRouteMap.pos_kalayanakatta]: [
    pos,
    { name: "Kalyanakatta", path: tmsRouteMap.pos_kalayanakatta },
  ],
  [tmsRouteMap.pos_prasadam]: [
    pos,
    { name: "Prasadam", path: tmsRouteMap.pos_prasadam },
  ],
  [tmsRouteMap.pos_products]: [
    pos,
    { name: "Products", path: tmsRouteMap.pos_products },
  ],
  [tmsRouteMap.pos_thulabharam]: [
    pos,
    { name: "Thulabharam", path: tmsRouteMap.pos_thulabharam },
  ],
  [tmsRouteMap.pos_accommodation]: [
    pos,
    { name: "Accommodation", path: tmsRouteMap.pos_accommodation },
  ],
  [tmsRouteMap.pos_acc_scan]: [
    pos,
    { name: "Accommodation Scan", path: tmsRouteMap.pos_acc_scan },
  ],
  [tmsRouteMap.pos_accommodation_dashboard]: [
    pos,
    {
      name: "Accommodation dashboard",
      path: tmsRouteMap.pos_accommodation_dashboard,
    },
  ],
  [tmsRouteMap.pos_accommodation_rooms]: [
    pos,
    { name: "Accommodation", path: tmsRouteMap.pos_accommodation },
    { name: "Rooms", path: tmsRouteMap.pos_accommodation_rooms },
  ],

  [tmsRouteMap.pos_tollgate]: [
    pos,
    { name: "Toll gate", path: tmsRouteMap.pos_tollgate },
  ],

  [tmsRouteMap.pos_check_in_scan]: [
    pos,
    { name: "Checkin Scan ", path: tmsRouteMap.pos_check_in_scan },
  ],
  [tmsRouteMap.pos_prasadm_scanner]: [
    pos,
    { name: "Prasadam Scanner", path: tmsRouteMap.pos_prasadm_scanner },
  ],
  [tmsRouteMap.pos_ticket_details]: [
    pos,
    { name: "Ticket Details ", path: tmsRouteMap.pos_ticket_details },
  ],

  // [tmsRouteMap.pos]: [pos, { name: "Entry Scan", path: tmsRouteMap.pos }],
  [tmsRouteMap.serviceManagement]: [
    { name: "Service management", path: tmsRouteMap.serviceManagement },
  ],
  //----------------------------protocol--------------------------//

  [tmsRouteMap.pos_protocol]: [
    pos,
    {
      name: "Protocol",
      path: "",
    },
  ],
  [tmsRouteMap.pos_protocol_print]: [
    pos,
    {
      name: "Protocol Print",
      path: "",
    },
  ],
  [tmsRouteMap.pos_protocol_dashboard]: [
    pos,
    {
      name: "Protocol Dashboard",
      path: "",
    },
  ],
  [tmsRouteMap.reports_protocol]: [
    reports,
    {
      name: "Protocol Reports",
      path: "",
    },
  ],
  [tmsRouteMap.reports_requests]: [
    reports,
    {
      name: "Reports Requests",
      path: "",
    },
  ],
  [tmsRouteMap.reports_requests_new]: [
    reports,
    {
      name: "Reports Requests",
      path: "",
    },
  ],
  //----------------------------accommodation---------------------//

  [tmsRouteMap.serviceManagement_accommodation]: [
    service,
    {
      name: "Accommodation",
      path: tmsRouteMap.serviceManagement_accommodation,
    },
  ],
  [tmsRouteMap.sm_accommodation_and]: [
    service,
    accommodation,
    {
      name: "Add New Block",
      path: tmsRouteMap.sm_accommodation_and,
    },
  ],
  [tmsRouteMap.sm_accommodation_disable]: [
    service,
    accommodation,
    {
      name: "Disable Dates",
      path: tmsRouteMap.sm_accommodation_disable,
    },
  ],
  [tmsRouteMap.sm_acc_manage_room_categories]: [
    service,
    accommodation,
    {
      name: "Manage Room Categories",
      path: "",
    },
  ],
  [tmsRouteMap.sms_acc_quota_management]: [
    service,
    accommodation,
    {
      name: "Batch Quota",
      path: "",
    },
  ],
  [tmsRouteMap.sm_acc_mrc_manage_room]: [
    service,
    accommodation,
    {
      name: "Manage Room Categories",
      path: tmsRouteMap.sm_acc_manage_room_categories,
    },
    {
      name: "Manage Room",
      path: "",
    },
  ],
  [tmsRouteMap.sm_acc_mrc_manage_quota]: [
    service,
    accommodation,
    {
      name: "Manage Room Categories",
      path: tmsRouteMap.sm_acc_manage_room_categories,
    },
    {
      name: "Manage Quota",
      path: "",
    },
  ],
  [tmsRouteMap.sm_acc_manage_pos]: [
    service,
    accommodation,
    {
      name: "Manage POS",
      path: "",
    },
  ],
  [tmsRouteMap.sm_donor_p_list]: [
    service,
    { name: "Donor Privileges", path: tmsRouteMap.sm_donor_p_list },
  ],
  [tmsRouteMap.sm_donor_p_create]: [
    service,
    privilege,
    { name: "Create Donor Privilege", path: tmsRouteMap.sm_donor_p_create },
  ],
  //----------------------------accommodation---------------------//

  //----------------------------products - sm---------------------//

  [tmsRouteMap.serviceManagement_products]: [
    service,
    {
      name: "Products",
      path: tmsRouteMap.serviceManagement_products,
    },
  ],
  [tmsRouteMap.sm_products_and]: [
    service,
    products,
    {
      name: "Add New Product",
      path: tmsRouteMap.sm_products_and,
    },
  ],
  [tmsRouteMap.sm_products_manage_pos]: [
    service,
    products,
    {
      name: "Manage POS",
      path: tmsRouteMap.sm_products_manage_pos,
    },
  ],
  [tmsRouteMap.sm_products_manage_files]: [
    service,
    products,
    {
      name: "File Management",
      path: "",
    },
  ],
  [tmsRouteMap.sm_products_manage_files_add]: [
    service,
    products,
    {
      name: "File Management",
      path: "",
    },
  ],
  //----------------------------products - sm---------------------//
  //----------------------------products - report---------------------//
  [tmsRouteMap.report_products]: [
    reports,
    {
      name: "Product Reports",
      path: tmsRouteMap.report_products,
    },
  ],
  //----------------------------products - report---------------------//
  [tmsRouteMap.serviceManagement_counter_management]: [
    service,
    {
      name: "POS Management",
      path: tmsRouteMap.serviceManagement_counter_management,
    },
  ],
  [tmsRouteMap.serviceManagement_counter_management_manage_shifts]: [
    service,
    {
      name: "POS Management",
      path: tmsRouteMap.serviceManagement_counter_management,
    },
    {
      name: "Manage Shifts",
      path: tmsRouteMap.serviceManagement_counter_management_manage_shifts,
    },
  ],
  [tmsRouteMap.serviceManagement_counter_management_manage_devices]: [
    service,
    {
      name: "POS Management",
      path: tmsRouteMap.serviceManagement_counter_management,
    },
    {
      name: "Manage Pos Devices",
    },
  ],

  [tmsRouteMap.serviceManagement_coupon_management]: [
    service,
    {
      name: "Coupon management",
      path: tmsRouteMap.serviceManagement_coupon_management,
    },
  ],
  [tmsRouteMap.serviceManagement_user_coupon_management]: [
    service,
    {
      name: "Coupon management",
      path: tmsRouteMap.serviceManagement_coupon_management,
    },
  ],
  [tmsRouteMap.serviceManagement_user_coupon_management]: [
    service,
    couponManagement,
    {
      name: "Registered User",
      path: tmsRouteMap.serviceManagement_user_coupon_management,
    },
  ],
  //---------------------Service-Darshanam------------------------//

  [tmsRouteMap.serviceManagement_darshanam]: [
    service,
    { name: "Darshanam", path: tmsRouteMap.serviceManagement_darshanam },
  ],

  [tmsRouteMap.smd_darshanam_add_new_darshanam]: [
    service,
    service_darshanam,
    {
      name: "Add New Darshanam",
      path: tmsRouteMap.smd_darshanam_add_new_darshanam,
    },
  ],
  [tmsRouteMap.smd_darshanam_darshanam_booking_details]: [
    service,
    service_darshanam,
    {
      name: "Darshanam booking",
      path: tmsRouteMap.smd_darshanam_darshanam_booking_details,
    },
  ],
  [tmsRouteMap.smd_darshanam_manage_counters]: [
    service,
    service_darshanam,
    { name: "Manage Slots", path: tmsRouteMap.smd_darshanam_manage_slots },
    {
      name: "Manage Counters",
      path: tmsRouteMap.smd_darshanam_manage_counters,
    },
  ],
  [tmsRouteMap.sms_darshanam_quota_management]: [
    service,
    service_darshanam,
    { name: "Batch Slots", path: "" },
  ],
  [tmsRouteMap.smd_darshanam_manage_quota]: [
    service,
    service_darshanam,
    { name: "Manage Slots", path: tmsRouteMap.smd_darshanam_manage_slots },
    {
      name: "Manage Counters",
      path: "",
    },
  ],
  [tmsRouteMap.smd_darshanam_manage_slots]: [
    service,
    service_darshanam,
    { name: "Manage Slots", path: tmsRouteMap.smd_darshanam_manage_slots },
  ],
  [tmsRouteMap.smd_darshanam_disable_dates]: [
    service,
    service_darshanam,
    { name: "Disable Darshanam ", path: "" },
  ],
  [tmsRouteMap.sms_darshanam_manage_partners]: [
    service,
    service_darshanam,
    {
      name: "Manage Partners",
      path: "",
    },
  ],
  //---------------------Service-Darshanam------------------------//
  //---------------------Service-Parakamani------------------------//
  [tmsRouteMap.sm_parakamani]: [
    service,
    { name: "Parakamani", path: tmsRouteMap.sm_parakamani },
  ],
  //---------------------Service-Parakamani------------------------//
  //---------------------Service-Parakamani------------------------//
  [tmsRouteMap.reports_parakamani]: [
    reports,
    { name: "Parakamani Reports", path: tmsRouteMap.reports_parakamani },
  ],
  //---------------------Service-Parakamani------------------------//
  //---------------------Bus------------------------//
  [tmsRouteMap.pos_bus]: [pos, { name: "Bus", path: tmsRouteMap.pos_bus }],
  [tmsRouteMap.sm_bus]: [service, { name: "Bus", path: tmsRouteMap.sm_bus }],
  [tmsRouteMap.sm_bus_manage_pos]: [
    service,
    bus,
    { name: "Manage Bus Counters", path: tmsRouteMap.sm_bus_manage_pos },
  ],
  [tmsRouteMap.sm_bus_add]: [
    service,
    bus,
    { name: "Add New Bus", path: tmsRouteMap.sm_bus_add },
  ],

  [tmsRouteMap.reports_bus]: [
    reports,
    { name: "Bus Reports", path: tmsRouteMap.reports_bus },
  ],
  //---------------------Bus------------------------//
  //---------------------Service Add-ons--------------------------//
  [tmsRouteMap.serviceManagement_addOns]: [
    service,
    { name: "Add Ons", path: tmsRouteMap.serviceManagement_addOns },
  ],

  [tmsRouteMap.sm_addons_add_new_addon]: [
    service,
    service_addOn,
    {
      name: "Add New Add-Ons",
      path: tmsRouteMap.sm_addons_add_new_addon,
    },
  ],

  [tmsRouteMap.sm_addons_manage_counters]: [
    service,
    service_addOn,
    { name: "Manage Slots", path: tmsRouteMap.sm_addons_manage_slots },
    {
      name: "Manage Counters",
      path: tmsRouteMap.sm_addons_manage_counters,
    },
  ],
  [tmsRouteMap.sm_addons_manage_quota]: [
    service,
    service_addOn,
    { name: "Manage Slots", path: tmsRouteMap.sm_addons_manage_slots },
    {
      name: "Manage Counters",
      path: "",
    },
  ],
  [tmsRouteMap.sm_addons_manage_slots]: [
    service,
    service_darshanam,
    { name: "Manage Slots", path: tmsRouteMap.sm_addons_manage_slots },
  ],
  // [tmsRouteMap.smd_darshanam_disable_dates]: [
  //   service,
  //   service_darshanam,
  //   { name: "Disable Darshanam ", path: "" },
  // ],
  //---------------------Service Add-ons--------------------------//
  //---------------------Service-Manual-Entry------------------------//
  [tmsRouteMap.serviceManagement_manual_entry]: [
    service,
    { name: "Manual Entry", path: tmsRouteMap.serviceManagement_manual_entry },
  ],
  [tmsRouteMap.serviceManagement_manual_entry_category]: [
    service,
    manual_entry,
    {
      name: "Manage Category",
      path: tmsRouteMap.serviceManagement_manual_entry_category,
    },
  ],
  [tmsRouteMap.pos_manual_entry]: [
    pos,
    { name: "Manual Entry", path: tmsRouteMap.pos_manual_entry },
  ],
  [tmsRouteMap.report_manual_entry]: [
    reports,
    { name: "Manual Entry", path: tmsRouteMap.report_manual_entry },
  ],
  //---------------------Service-Manual-Entry------------------------//

  //---------------------Service-Rents-and-Leases-------------------------//
  [tmsRouteMap.serviceManagement_rents_and_leases]: [
    service,
    {
      name: "Rents and Leases",
      path: tmsRouteMap.serviceManagement_rents_and_leases,
    },
  ],
  [tmsRouteMap.sm_ral_buildings]: [
    service,
    {
      name: "Buildings and Shops",
      path: tmsRouteMap.sm_ral_buildings,
    },
  ],
  [tmsRouteMap.sm_ral_lands]: [
    service,
    rents_and_leases,
    rents_and_leases_lands,
    { name: "Land Creation", path: "" },
  ],

  //Lands - Creation List

  [tmsRouteMap.sm_ral_lands_land_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_lands,

    { name: "Land Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_lands_survey_no_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_lands,

    { name: "Survey No Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_lands_survey_breakup_no_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_lands,

    { name: "Survey Breakup Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_lands_land_lease_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_lands,

    { name: "Land Lease Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_lands_arears_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_lands,

    { name: "Arrear Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_lands_enchroament_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_lands,

    { name: "Encroachment Creation", path: "" },
  ],

  [tmsRouteMap.pos_rents_and_leases]: [
    service,
    {
      name: "Rents and Leases",
      path: tmsRouteMap.pos_rents_and_leases,
    },
  ],

  //---------------------Buildings---------------------------------//
  [tmsRouteMap.sm_ral_buildings_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_buildings,
    { name: "Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_buildings_createdList]: [
    service,
    rents_and_leases,
    rents_and_leases_buildings,

    { name: "Created Lists", path: "" },
  ],

  //Buildings - Created Lists

  //Buildings - Creation List

  [tmsRouteMap.sm_ral_buildings_created_building_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_buildings,

    { name: "Building Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_buildings_blocks_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_buildings,

    { name: "Block Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_buildings_shops_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_buildings,

    { name: "Shop Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_buildings_buildings_lease_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_buildings,

    { name: "Lease Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_buildings_arears_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_buildings,

    { name: "Arrear Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_buildings_enchroament_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_buildings,

    { name: "Encroachment Creation", path: "" },
  ],
  //---------------------Service-licenses------------------//
  [tmsRouteMap.sm_ral_licenses]: [
    service,
    rents_and_leases,
    rents_and_leases_licenses,
  ],
  [tmsRouteMap.sm_ral_license_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_licenses,
    { name: "License Creation", path: "" },
  ],

  [tmsRouteMap.sm_ral_license_license_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_licenses,
    { name: "License Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_license_lease_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_licenses,
    { name: "Lease Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_license_arears_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_licenses,

    { name: "Arrear Creation", path: "" },
  ],

  //---------------------Service-licenses------------------//

  //---------------------Service-fdr------------------//
  [tmsRouteMap.sm_ral_fdr]: [service, rents_and_leases, rents_and_leases_fdr],
  [tmsRouteMap.sm_ral_fdr_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_fdr,
    { name: "Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_fdr_createdList]: [
    service,
    rents_and_leases,
    rents_and_leases_fdr,
    { name: "Created Lists", path: "" },
  ],

  [tmsRouteMap.sm_ral_fdr_account_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_fdr,
    { name: "Account Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_fdr_bank_account_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_fdr,
    { name: "Bank Account Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_fdr_investment_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_fdr,

    { name: "Investment Creation", path: "" },
  ],
  [tmsRouteMap.sm_ral_fdr_fdr_creation]: [
    service,
    rents_and_leases,
    rents_and_leases_fdr,

    { name: "FDR Creation", path: "" },
  ],

  [tmsRouteMap.sm_ral_fdr_account_list]: [
    service,
    rents_and_leases,
    rents_and_leases_fdr,
    { name: "Account List", path: "" },
  ],
  [tmsRouteMap.sm_ral_fdr_bank_account_list]: [
    service,
    rents_and_leases,
    rents_and_leases_fdr,
    { name: "Bank Account List", path: "" },
  ],
  [tmsRouteMap.sm_ral_fdr_investment_list]: [
    service,
    rents_and_leases,
    rents_and_leases_fdr,

    { name: "Investment List", path: "" },
  ],
  [tmsRouteMap.sm_ral_fdr_fdr_list]: [
    service,
    rents_and_leases,
    rents_and_leases_fdr,

    { name: "FDR List", path: "" },
  ],
  //---------------------Service-fdr------------------//

  //---------------------Service-Rents-and-Leases------------------//

  //Reports - RAL--//
  [tmsRouteMap.reports_rents_and_leases]: [
    reports,
    {
      name: "Rents and Leases",
      path: tmsRouteMap.reports_rents_and_leases,
    },
  ],
  //lands-reports
  [tmsRouteMap.reports_ral_lands]: [
    reports,
    rents_and_leases_lands_reports,
    { name: "Land Report", path: "" },
  ],
  [tmsRouteMap.reports_ral_lands_leases]: [
    reports,
    rents_and_leases_lands_reports,
    { name: "Lease Report", path: "" },
  ],
  [tmsRouteMap.reports_ral_lands_arrears]: [
    reports,
    rents_and_leases_lands_reports,
    { name: "Arrear Report", path: "" },
  ],
  [tmsRouteMap.reports_ral_lands_encroachment]: [
    reports,
    rents_and_leases_lands_reports,
    { name: "Encroachment Report", path: "" },
  ],

  //building-reports
  [tmsRouteMap.reports_ral_buildings]: [
    reports,
    rents_and_leases_building_reports,
    { name: "Building Report", path: "" },
  ],
  [tmsRouteMap.reports_ral_buildings_leases]: [
    reports,
    rents_and_leases_building_reports,
    { name: "Lease Report", path: "" },
  ],
  [tmsRouteMap.reports_ral_buildings_arrears]: [
    reports,
    rents_and_leases_building_reports,
    { name: "Arrear Report", path: "" },
  ],
  [tmsRouteMap.reports_ral_buildings_encroachment]: [
    reports,
    rents_and_leases_building_reports,
    { name: "Encroachment Report", path: "" },
  ],

  //building-reports
  [tmsRouteMap.reports_ral_licenses]: [
    reports,
    rents_and_leases_license_reports,
  ],
  [tmsRouteMap.reports_ral_licenses_leases]: [
    reports,
    rents_and_leases_license_reports,
    { name: "Lease Report", path: "" },
  ],
  [tmsRouteMap.reports_ral_licenses_arrears]: [
    reports,
    rents_and_leases_license_reports,
    { name: "Arrear Report", path: "" },
  ],

  //--------- RAL----------------------------------//

  //---------------------Service-Donation--------------------------//

  [tmsRouteMap.serviceManagement_donation]: [
    service,
    { name: "Donation", path: tmsRouteMap.serviceManagement_donation },
  ],
  [tmsRouteMap.sms_donation_booking_details]: [
    service,
    donation,
    {
      name: "Donation Booking Details",
      path: tmsRouteMap.sms_donation_booking_details,
    },
  ],
  [tmsRouteMap.sms_donation_donor_registartion]: [
    service,
    donation,
    {
      name: "Donar Registarion",
      path: tmsRouteMap.sms_donation_donor_registartion,
    },
  ],
  [tmsRouteMap.sms_donation_manage_counters]: [
    service,
    donation,
    {
      name: "Manage Donation Counters",
      path: tmsRouteMap.sms_donation_manage_counters,
    },
  ],
  [tmsRouteMap.sms_add_new_donation]: [
    service,
    donation,
    { name: "Add New Donation", path: tmsRouteMap.sms_add_new_donation },
  ],
  [tmsRouteMap.sms_donation_manage_partners]: [
    service,
    donation,
    {
      name: "Manage Partners",
      path: "",
    },
  ],
  //---------------------Service-Donation-------------------------//

  //------------------Service kalayanakatta-----------//
  [tmsRouteMap.serviceManagement_kalayanakatta]: [
    service,
    {
      name: "Kalyana katta",
      path: tmsRouteMap.serviceManagement_kalayanakatta,
    },
  ],
  [tmsRouteMap.sm_kalyanakatta_manage_tonsure]: [
    service,
    kalyankatta,
    {
      name: "Manage POS",
      path: tmsRouteMap.sm_kalyanakatta_manage_tonsure,
    },
  ],
  [tmsRouteMap.sm_kalyanakatta_manage_performer]: [
    service,
    kalyankatta,
    {
      name: "Manage Performer",
      path: tmsRouteMap.sm_kalyanakatta_manage_performer,
    },
  ],
  [tmsRouteMap.sm_kalyanakatta_add_new_kalyanakatta]: [
    service,
    kalyankatta,
    {
      name: "Add New Kalyanakatta",
      path: tmsRouteMap.sm_kalyanakatta_add_new_kalyanakatta,
    },
  ],
  [tmsRouteMap.reports_kalyanakatta]: [
    reports,
    {
      name: "Kalyanakatta Report",
      path: tmsRouteMap.reports_kalyanakatta,
    },
  ],
  [tmsRouteMap.reports_kalyanakatta_performer_report]: [
    reports,
    {
      name: "Performer Report",
      path: tmsRouteMap.reports_kalyanakatta_performer_report,
    },
  ],
  [tmsRouteMap.reports_kalyanakatta_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_kalyanakatta_summary_report,
    },
  ],
  //------------------Service Kalyanakatta------------//
  [tmsRouteMap.serviceManagement_parokshaseva]: [
    service,
    { name: "Paroksha seva", path: tmsRouteMap.serviceManagement_parokshaseva },
  ],
  //---------------------Service-Prasadam-------------------------//

  [tmsRouteMap.serviceManagement_prasadam]: [
    service,
    { name: "Prasadam", path: tmsRouteMap.serviceManagement_prasadam },
  ],
  [tmsRouteMap.smd_prasadam_booking_details]: [
    service,
    prasadam,
    {
      name: "Prasadam Booking Details",
      path: tmsRouteMap.smd_prasadam_booking_details,
    },
  ],
  [tmsRouteMap.smd_prasadam_manage_counter]: [
    service,
    prasadam,
    {
      name: "Manage Prasadam Counters",
      path: tmsRouteMap.smd_prasadam_manage_counter,
    },
  ],
  [tmsRouteMap.smd_prasadam_add_new_prasadam]: [
    service,
    prasadam,
    {
      name: "Add New Prasadam",
      path: tmsRouteMap.smd_prasadam_add_new_prasadam,
    },
  ],
  [tmsRouteMap.sm_prasadam_inventory]: [
    service,
    { name: "Prasadam Inventory", path: tmsRouteMap.sm_prasadam_inventory },
  ],
  [tmsRouteMap.sm_prasadam_inventory_add_new]: [
    service,
    prasadamInventory,
    {
      name: "Add New ",
      path: tmsRouteMap.sm_prasadam_inventory_add_new,
    },
  ],
  [tmsRouteMap.sm_prasadam_inventory_stock]: [
    service,
    prasadamInventory,
    {
      name: "Stock Level",
      path: tmsRouteMap.sm_prasadam_inventory_stock,
    },
  ],

  //---------------------Service-Prasadam-------------------------//

  //---------------------Service-Sevas------------------------//
  [tmsRouteMap.serviceManagement_sevas]: [
    service,
    {
      name: "Sevas",
      path: tmsRouteMap.serviceManagement_sevas,
    },
  ],

  [tmsRouteMap.sms_add_new_seva]: [
    service,
    service_sevas,
    {
      name: "Add new Sevas",
      path: tmsRouteMap.sms_add_new_seva,
    },
  ],
  [tmsRouteMap.sms_seva_booking_details]: [
    service,
    service_sevas,
    {
      name: "Seva Booking Details",
      path: tmsRouteMap.sms_seva_booking_details,
    },
  ],
  [tmsRouteMap.sms_sevas_manage_slots]: [
    service,
    service_sevas,
    {
      name: "Manage Slots",
      path: tmsRouteMap.sms_sevas_manage_slots,
    },
  ],
  [tmsRouteMap.sms_seva_quota_management]: [
    service,
    service_sevas,
    {
      name: "Batch Quota",
      path: "",
    },
  ],
  [tmsRouteMap.sms_sevas_manage_counters]: [
    service,
    service_sevas,
    {
      name: "Manage Slots",
      path: tmsRouteMap.sms_sevas_manage_slots,
    },
    {
      name: "Manage Counters",
      path: tmsRouteMap.sms_sevas_manage_counters,
    },
  ],
  [tmsRouteMap.sms_sevas_manage_quota]: [
    service,
    service_sevas,
    {
      name: "Manage Slots",
      path: tmsRouteMap.sms_sevas_manage_slots,
    },
    {
      name: "Manage Quota",
      path: "",
    },
  ],
  [tmsRouteMap.sms_sevas_manage_partners]: [
    service,
    service_sevas,
    {
      name: "Manage Partners",
      path: "",
    },
  ],
  //---------------------Service-Sevas------------------------//

  //--------------------Service entry scan--------------------//
  [tmsRouteMap.serviceManagement_entryScan]: [
    service,
    {
      name: "Entry Scan",
      path: tmsRouteMap.serviceManagement_entryScan,
    },
  ],

  [tmsRouteMap.sm_entry_scan_add_new]: [
    service,
    entryScan,
    {
      name: "Add new Entry Scan",
      path: tmsRouteMap.sm_entry_scan_add_new,
    },
  ],
  [tmsRouteMap.sm_entry_scan_manage_counter]: [
    service,
    entryScan,
    {
      name: "Manage Counters",
      path: tmsRouteMap.sm_entry_scan_manage_counter,
    },
  ],
  [tmsRouteMap.reports_entryScan]: [
    reports,
    {
      name: "Entry Scan reports",
      path: tmsRouteMap.reports_entryScan,
    },
  ],
  //----------------------------------------------------------//

  [tmsRouteMap.serviceManagement_shift_management]: [
    service,
    {
      name: "Shift management",
      path: tmsRouteMap.serviceManagement_shift_management,
    },
  ],
  [tmsRouteMap.serviceManagement_thulabharam]: [
    service,
    { name: "Thulabharam", path: tmsRouteMap.serviceManagement_thulabharam },
  ],
  [tmsRouteMap.sm_thulabharam_add_new_thulabharam]: [
    service,
    thulabharam,
    {
      name: "Add new Thulabharam",
      path: tmsRouteMap.sm_thulabharam_add_new_thulabharam,
    },
  ],
  [tmsRouteMap.serviceManagement_counter_management]: [
    service,
    {
      name: "POS management",
      path: tmsRouteMap.serviceManagement_counter_management,
    },
  ],
  //----------------------Services Publications------------------//
  [tmsRouteMap.report_publication]: [
    reports,
    { name: "Publications", path: "" },
  ],
  [tmsRouteMap.serviceManagement_publications]: [
    service,
    { name: "Publications", path: tmsRouteMap.serviceManagement_publications },
  ],
  [tmsRouteMap.pos_publication]: [
    pos,
    { name: "Publications", path: tmsRouteMap.pos_publication },
  ],
  [tmsRouteMap.sm_publication_and]: [
    service,
    publication,
    {
      name: "Add new Publication",
      path: tmsRouteMap.sm_publication_and,
    },
  ],
  [tmsRouteMap.sm_publication_manage_pos]: [
    service,
    publication,
    {
      name: "Manage Counters",
      path: tmsRouteMap.sm_publication_manage_pos,
    },
  ],
  [tmsRouteMap.sm_publication_manage_files]: [
    service,
    publication,
    {
      name: "Manage Files",
      path: tmsRouteMap.sm_publication_manage_files,
    },
  ],
  //----------------------Services Publications------------------//
  //----------------------Services Tollgate----------------------//
  [tmsRouteMap.serviceManagement_tollgate]: [
    service,
    {
      name: "Toll Gate",
      path: tmsRouteMap.serviceManagement_tollgate,
    },
  ],
  [tmsRouteMap.sm_tollgate_add_new_tollgate]: [
    service,
    tollgate,
    {
      name: "Add new Toll Gate",
      path: tmsRouteMap.sm_tollgate_add_new_tollgate,
    },
  ],
  [tmsRouteMap.sm_tollgate_booking_details]: [
    service,
    tollgate,
    {
      name: "Toll Gate Booking Details",
      path: tmsRouteMap.sm_tollgate_booking_details,
    },
  ],

  [tmsRouteMap.sm_tollgate_manage_counter]: [
    service,
    tollgate,
    {
      name: "(Slots)-Manage Counters",
      path: tmsRouteMap.sm_tollgate_manage_counter,
    },
  ],
  //--------------------------------------------------------------//

  //--------------------------------------------------------------//

  [tmsRouteMap.serviceManagement_misc]: [
    service,
    {
      name: "Miscellaneous",
      path: tmsRouteMap.serviceManagement_misc,
    },
  ],
  [tmsRouteMap.sm_misc_add_new_misc]: [
    service,
    misc,
    {
      name: "Add New Miscellaneous",
      path: tmsRouteMap.sm_misc_add_new_misc,
    },
  ],
  [tmsRouteMap.sm_misc_booking_details]: [
    service,
    misc,
    {
      name: "Miscellaneous Booking Details",
      path: tmsRouteMap.sm_misc_booking_details,
    },
  ],

  [tmsRouteMap.sm_misc_manage_counter]: [
    service,
    misc,
    {
      name: "(Slots)-Manage Counters",
      path: tmsRouteMap.sm_misc_manage_counter,
    },
  ],
  //--------------------------------------------------------------//

  [tmsRouteMap.orders]: [{ name: "Orders", path: tmsRouteMap.orders }],
  [tmsRouteMap.reports]: [{ name: "Reports", path: tmsRouteMap.reports }],
  [tmsRouteMap.reports_shift_report]: [
    reports,
    { name: "Shift Report", path: tmsRouteMap.reports_shift_report },
  ],
  [tmsRouteMap.reports_view_shift_transactions]: [
    reports,
    {
      name: "Shift Transactions",
      path: tmsRouteMap.reports_view_shift_transactions,
    },
  ],
  [tmsRouteMap.reports_consolidated_reports]: [
    reports,
    {
      name: "Consolidated Report",
      path: tmsRouteMap.reports_consolidated_reports,
    },
  ],
  [tmsRouteMap.reports_view_shift_scan_logs]: [
    reports,
    {
      name: "Scan Log Transactions",
      path: tmsRouteMap.reports_view_shift_scan_logs,
    },
  ],
  [tmsRouteMap.reports_scan_logs]: [
    reports,
    {
      name: "Scan Logs",
      path: tmsRouteMap.reports_scan_logs,
    },
  ],

  //---------------------reports-darshanam-----------//
  [tmsRouteMap.reports_darshanam]: [
    reports,
    { name: "Darshanam Report", path: tmsRouteMap.reports_darshanam },
  ],
  [tmsRouteMap.reports_darshanam_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_darshanam_summary_report,
    },
  ],
  //---------------------reports-darshanam-----------//
  [tmsRouteMap.reports_misc]: [
    reports,
    {
      name: "Miscellaneous Report",
      path: tmsRouteMap.reports_misc,
    },
  ],
  //---------------------reports-seva-----------//
  [tmsRouteMap.reports_seva]: [
    reports,
    { name: "Seva Report", path: tmsRouteMap.reports_seva },
  ],
  [tmsRouteMap.reports_seva_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_seva_summary_report,
    },
  ],
  //---------------------reports-seva-----------//

  //---------------------reports-prasadam-----------//
  [tmsRouteMap.reports_prasadam]: [
    reports,
    { name: "Prasadam Report", path: tmsRouteMap.reports_prasadam },
  ],
  [tmsRouteMap.reports_prasadam_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_prasadam_summary_report,
    },
  ],
  //---------------------reports-prasadam-----------//

  //---------------------reports-donation-----------//
  [tmsRouteMap.reports_donation]: [
    reports,
    { name: "Donation Report", path: tmsRouteMap.reports_donation },
  ],
  [tmsRouteMap.reports_donation_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_donation_summary_report,
    },
  ],
  //---------------------reports-donation-----------//

  //---------------------reports-thulabharam-----------//
  [tmsRouteMap.reports_thulabharam]: [
    reports,
    { name: "Thulabahram Report", path: tmsRouteMap.reports_thulabharam },
  ],
  [tmsRouteMap.reports_thulabharam_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_thulabharam_summary_report,
    },
  ],
  //---------------------reports-thulabharam-----------//
  //---------------------reports-print-----------//
  [tmsRouteMap.reports_print_logs]: [
    reports,
    { name: "Print Logs", path: tmsRouteMap.reports_print_logs },
  ],
  //---------------------reports-print-----------//

  //---------------------reports-accommodation-----------//
  [tmsRouteMap.reports_accommodation]: [
    reports,
    { name: "Accommodation Report", path: tmsRouteMap.reports_thulabharam },
  ],
  [tmsRouteMap.reports_accommodation_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_accommodation_summary_report,
    },
  ],
  [tmsRouteMap.reports_accommodation_renewal_report]: [
    reports,
    {
      name: "Renewal Report",
      path: tmsRouteMap.reports_accommodation_renewal_report,
    },
  ],
  //---------------------reports-misc-----------//
  [tmsRouteMap.reports_misc]: [
    reports,
    {
      name: "Miscellaneous Report",
      path: tmsRouteMap.reports_misc,
    },
  ],
  [tmsRouteMap.reports_accommodation_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_misc_summary_report,
    },
  ],
  //---------------------reports-tollgate-----------//
  [tmsRouteMap.reports_tollgate]: [
    reports,
    {
      name: "Tollgate Report",
      path: tmsRouteMap.reports_tollgate,
    },
  ],
  [tmsRouteMap.reports_tollgate_summary_report]: [
    reports,
    {
      name: "Summary Report",
      path: tmsRouteMap.reports_tollgate_summary_report,
    },
  ],

  //---------------------reports-accommodation-----------//

  [tmsRouteMap.userManagement]: [
    { name: "User Management", path: tmsRouteMap.userManagement },
  ],
  [tmsRouteMap.userManagement_manage_users]: [
    { name: "User Management", path: tmsRouteMap.userManagement },
    { name: "Manage Users", path: tmsRouteMap.userManagement_manage_users },
  ],
  [tmsRouteMap.userManagement_user_details]: [
    { name: "User Management", path: tmsRouteMap.userManagement },
    { name: "User Details", path: tmsRouteMap.userManagement_user_details },
  ],
  [tmsRouteMap.support]: [{ name: "Support", path: tmsRouteMap.support }],
  [tmsRouteMap.calender]: [{ name: "Calender", path: tmsRouteMap.calender }],
  [tmsRouteMap.bookings]: [{ name: "Bookings", path: tmsRouteMap.bookings }],
};

export default tmsBreadcrumbMap;
