import Axios from "../../utils/tms-axios/tms-axios";
import ReportsAxios from "../../utils/tms-axios/tms-reports-axios";
const TmsDarshanamService = {
  getDarshanam: async (page, limit) => {
    const resp = await Axios.get(
      `v1/darshanam/all?page=${page}&limit=${limit}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createDarshanam: async (body) => {
    const resp = await Axios.post(`v1/darshanam/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  deleteDarshanam: async (productId) => {
    const resp = await Axios.delete(`v1/darshanam/delete/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  viewDarshanam: async (productId) => {
    const resp = await Axios.get(`v1/darshanam/by-id/${productId}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  editDarshanam: async (productId, body) => {
    const resp = await Axios.put(`v1/darshanam/update/${productId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  //darshanam-slots
  getDarshanamSlots: async (id) => {
    const resp = await Axios.get(`v1/darshanam-slot/product-slots/${id}`, {
      headers: {
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  createDarshanamSlots: async (body) => {
    const resp = await Axios.post(`v1/darshanam-slot/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editDarshanamSlots: async (body, slotId) => {
    const resp = await Axios.put(`v1/darshanam-slot/update/${slotId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  deleteDarshanamSlots: async (slotId) => {
    const resp = await Axios.delete(`v1/darshanam-slot/delete/${slotId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  updateTerms: async (productId, termsData, onlineterms, ticketType) => {
    const resp = await Axios.patch(
      `v1/darshanam/metadata/${productId}`,
      {
        termsAndConditions: termsData,
        onlineTermsAndConditions: onlineterms,
        ticketType: ticketType ? "mini_ticket" : "full_ticket",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  //darshanam-slots-counters

  //darshanam-slots
  getDarshanamSlotCounter: async (id) => {
    const resp = await Axios.get(
      `v1/darshanam-counter-slot/by-product-slot-id/${id}`,
      {
        headers: {
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  createDarshanamSlotCounter: async (body) => {
    const resp = await Axios.post(`v1/darshanam-counter-slot/create`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  editDarshanamSlotCounter: async (body, slotId) => {
    const resp = await Axios.put(
      `v1/darshanam-counter-slot/update/${slotId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  deleteDarshanamSlotCounter: async (slotId) => {
    const resp = await Axios.delete(
      `v1/darshanam-counter-slot/delete/${slotId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  //darshanam booking

  fetchDarshanamBookingSlots: async (tickets) => {
    const resp = await Axios.get(
      `v1/darshanam-counter-booking/valid-counter-slots?no_of_tickets=${tickets}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  fetchProtocolDarshanam: async () => {
    const resp = await Axios.get(`v1/darshanam-online-booking/valid-products`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  fetchProtocolSlots: async (fromDate, toDate, multipleProductIds) => {
    let url = `v1/darshanam-slot-daily-quota?fromDate=${fromDate}&toDate=${toDate}&multipleProductIds=${multipleProductIds}&type=protocol`;
    // &onlyOnlineEnabled=false&type=protocol${multipleProductIds
    //   .map((item) => `&multipleProductIds=${Number(item)}`)
    //   .join("")}
    const resp = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  darshanamBooking: async (body, paymentType = "CASH") => {
    const resp = await Axios.post(
      `v1/counter-cart/new`,
      {
        darshanamBookings: body,
        multiTicketsLinked: false,
        paymentType: paymentType,
        cashRemarks: "string",
        cashReference: "string",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  darshanamReport: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    dateType,
    sortType,
    counter,
    shift,
    slot,
    darshanamType,
  }) => {
    let query = `v1/darshanam-counter-report/booking?format=${format}&page=${tableCurrentPage}&limit=${tablePageSize}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${sortType}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (slot && slot !== "") {
      query += `&darshanamSlotId=${slot}`;
    }
    if (darshanamType && darshanamType !== "") {
      query += `&productId=${darshanamType}`;
    }
    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  darshanamReportOnline: async ({
    format,
    tableCurrentPage,
    tablePageSize,
    fromDate,
    toDate,
    dateType,
    sortType,
    counter,
    shift,
    slot,
    darshanamType,
    paymentStatus,
    selectedPartner,
  }) => {
    let query = `v1/darshanam-online-report/booking?format=${format}&paymentStatus=${paymentStatus}&page=${tableCurrentPage}&limit=${tablePageSize}&bookingFromDate=${fromDate}&bookingToDate=${toDate}&dateType=${dateType}&order=${sortType}`;

    if (counter && counter !== "") {
      query += `&counterId=${counter}`;
    }
    if (shift && shift !== "") {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (slot && slot !== "") {
      query += `&darshanamSlotId=${slot}`;
    }
    if (darshanamType && darshanamType !== "") {
      query += `&productId=${darshanamType}`;
    }
    if (selectedPartner !== "" && selectedPartner) {
      query += `&partnerCode=${selectedPartner}`;
    }
    const axios = format === "email" ? Axios : ReportsAxios;
    const resp = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },

  //disable-dates
  fetchdisabledates: async (productId, page, limit) => {
    const resp = await Axios.get(
      `v1/darshanam/disable-date/by-product-id/${productId}?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  createDisableDates: async (
    productIds,
    startDate,
    endDate,
    description,
    onlineDisabled,
    counterDisabled
  ) => {
    const resp = await Axios.post(
      `v1/darshanam/disable-date/create`,
      {
        productIds: productIds,
        startDate: startDate,
        endDate: endDate,
        description: description,
        onlineDisabled: onlineDisabled,
        counterDisabled: counterDisabled,
        protocolDisabled: false,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },

  deleteDisableDate: async (id) => {
    const resp = await Axios.delete(`v1/darshanam/disable-date/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  getConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    counter,
    shift,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/darshanam-counter-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (counter) {
      query += `&counterId=${counter}`;
    }
    if (shift) {
      query += `&counterShiftTimingId=${shift}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  getOnlineConsolidateReport: async ({
    format,
    productId,
    from,
    to,
    groupBy,
    bookingType,
  }) => {
    let query = `v1/darshanam-online-report/consolidated-ticket-collection-for-product?format=${format}&bookingFromDate=${from}&bookingToDate=${to}`;

    if (productId !== "") {
      query += `&productId=${productId}`;
    }
    if (groupBy !== "") {
      query += `&groupBy=${groupBy}`;
    }
    if (bookingType) {
      query += `&dateType=${bookingType}`;
    }
    let res = await ReportsAxios.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },

  getQuotaData: async ({ from, to, productIds, type }) => {
    const resp = await Axios.get(
      `v1/darshanam-slot-daily-quota?fromDate=${from}&toDate=${to}&productSlotId=${productIds}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": localStorage.getItem("templeId"),
        },
      }
    );
    return resp.data;
  },
  updateQuotaData: async (body) => {
    const res = await Axios.post(`v1/darshanam-slot-daily-quota`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return res.data;
  },
  getPartnersForDarshanam: async (productId) => {
    const resp = await Axios.get(`v1/darshanam/partners/${productId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
  darshnamPartnerAccess: async (body) => {
    const resp = await Axios.patch(`v1/darshanam/partner-access`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-Temple-Id": localStorage.getItem("templeId"),
      },
    });
    return resp.data;
  },
};

export default TmsDarshanamService;
